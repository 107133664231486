import React, { useState } from "react";
import {
    BooleanField,
    Datagrid,
    Edit,
    EditButton,
    List,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";

const defaultQuery = "listOrders";

export const AllergensList = (props) => {
    const [query, setQuery] = useState(defaultQuery);

    return (
        <List {...props} pagination={false}>
            <Datagrid>
                <TextField source="id" label="id" sortable={false}/>
                <TextField source="name" label="Nombre" sortable={false} />
                {/*<TextField source="price" label="Precio" sortable={false} />*/}
                {/*<TextField source="status" label="Estado" sortable={false} />*/}
                {/*<TextField source="stock" label="Inventario" sortable={false} />*/}
                {/*<ShowButton label="Ver" />*/}
                {/*<EditButton label="Editar" />*/}
            </Datagrid>
        </List>
    );
};
