import { useMutation, UseMutationResult, useQuery } from "react-query";
import Endpoints from "../constants/Endpoints";
import { UseMutationOptions } from "react-query";
import axiosInstance from "./api";
import type { UseQueryOpts } from "../models/queryTypes";
import { UseQueryResult } from "react-query";
import { QueryTypes } from "../models/queryTypes";

interface UploadMastarCatalogFormdata {
  supplier_id: number;
  file_url: string;
}

interface ConflictResolutionsFormdata {
  name: string;
  code: string;
  unit: string;
  price: number;
  sku: string;
  stock: number;
  brand: string;
  product_category_id: number;
  available: boolean;
}

interface UploadFileFormData {
  file: File;
}

interface RemoveBulkProductsFormdata {
  product_ids: number[];
}

const _uploadMasterCatalog = async (formData: UploadMastarCatalogFormdata): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.post(
        `${Endpoints.SUPPLIER_MASTER_CATALOG_UPLOAD}`,
        formData
      )
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useUploadMasterCatalog = (options?: UseMutationOptions): UseMutationResult => {
  return useMutation(
    (uploadMasterCatalogFormData) =>
      _uploadMasterCatalog(uploadMasterCatalogFormData),
    options
  );
};

const _getGetCatalog = async (supplierId: string): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.get(
        `${Endpoints.SUPPLIER_MASTER_CATALOG}?filter.supplier_id=${supplierId}`
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(`Unknown error: ${error}`);
  }
};

export const useGetCatalog = (supplierId, { refetchOnFocus, ...opts }: UseQueryOpts): UseQueryResult => {
  return useQuery(QueryTypes.CATALOG, () => _getGetCatalog(supplierId), {
    cacheTime: 0,
    ...opts,
  });
};

const _uploadFile = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response: any = (
      await axiosInstance.post(`${Endpoints.UPLOAD_FILE}`, formData)
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useUploadFile = (options?: UseMutationOptions): UseMutationResult => {
  return useMutation(
    (uploadFileFormData) => _uploadFile(uploadFileFormData),
    options
  );
};

const _catalogConflictResolutions = async (formData: ConflictResolutionsFormdata): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.post(
        `${Endpoints.SUPPLIER_MASTER_CATALOG_CONFLICT_RESOLUTIONS}`,
        formData
      )
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useCatalogConflictResolutions = (options?: UseMutationOptions): UseMutationResult => {
  return useMutation(
    (uploadMasterCatalogFormData) =>
      _catalogConflictResolutions(uploadMasterCatalogFormData),
    options
  );
};

const _removeBulkProducts = async (formData: ConflictResolutionsFormdata): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.delete(`${Endpoints.REMOVE_BULK_PRODUCTS}`, {
        data: formData,
      })
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useRemoveBulkProducts = (options?: UseMutationOptions): UseMutationResult => {
  return useMutation(
    (removeBulkProducts: RemoveBulkProductsFormdata) =>
      _removeBulkProducts(removeBulkProducts),
    options
  );
};
