import { red } from "@material-ui/core/colors";

export const listoCustomTheme = {
  palette: {
    primary: {
      light: "#152874",
      main: "#000",
      dark: "#152874",
      contrastText: "#fff",
    },
    secondary: {
      light: "#152874",
      main: "#152874",
      dark: "#152874",
      contrastText: "#fff",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      styleOverrides: {
        root: {
          // Name of the rule
          color: "#fff", // Some CSS
          background: "#000",
          borderRadius: 50,
          marginRight: 5,
          marginLeft: 5,
          padding: "15px !important",
          "&:hover": {
            color: "#fff",
            backgroundColor: "#152874",
          },
        },
        textPrimary: {
          color: "#fff",
          "&:hover": {
            backgroundColor: "#152874",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: "#152874",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: "#484848",
          marginRight: 10,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          fontWeight: "bold",
          "&:hover": {
            color: "#fff",
            backgroundColor: "#152874",
          },
        },
        textColorInherit: {
          color: "#fff",
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        content: {
          minHeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    RaLogin: {
      styleOverrides: {
        main: {
          backgroundImage:
            "radial-gradient(circle at 50% 14em, #152874A5 0%, #FF4D00 60%, #FF4D00 100%)",
        },
      },
    },
    RaShow: {
      styleOverrides: {
        main: {
          height: "100%",
          maxHeight: "100%",
        },
      },
    },
    // MuiTableHead: {
    //     root: {
    //         textAlignLast: 'center'
    //     }
    // }
  },
};
