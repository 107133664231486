export default {
  APP_SETTINGS: "/app-settings",
  DOWNLOAD_CATALOG: "/products/catalog/download",
  FAVORITE_PRODUCTS: "/favorite-products",
  GET_SUPPLIER_FILES: "/file-uploads/supplier-file",
  REMOVE_BULK_PRODUCTS: "/products/bulk-remove",
  RESTAURANT_DEACTIVATE: "/restaurants/{id}/deactivate",
  RESTAURANT_GET: "/restaurants/",
  RESTAURANTS_SUPPLIERS: "/restaurants-suppliers",
  RESTAURANT_USERS: "/restaurant-users",
  SUPPLIER_DEACTIVATE: "/suppliers/{id}/deactivate",
  SUPPLIER_DELETE: "/suppliers/{id}",
  SUPPLIER_MASTER_CATALOG_CONFLICT_RESOLUTIONS:
    "/product-uploads/conflict-resolutions",
  SUPPLIER_MASTER_CATALOG_UPLOAD: "/product-uploads/upload",
  SUPPLIER_MASTER_CATALOG: "/product-uploads/csv",
  SUPPLIER_UPDATE_HAS_MASTER_CATALOG: "/suppliers/{id}",
  UPLOAD_FILE: "/file-uploads",
  UPLOAD_PRODUCT_IMAGE: "/file-uploads/product",
};
