import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BackIcon from "@mui/icons-material/ArrowBack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import React, { useCallback, useState } from "react";
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Confirm,
  Create,
  Datagrid,
  DateField,
  SaveButton as DefaultSaveButton,
  Edit,
  EditButton,
  List,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { MultiSelect } from "react-multi-select-component";
import ImageViewer from "react-simple-image-viewer";
import { toast } from "react-toastify";
import MultiSelectLocalization from "../constants/MultiSelectLocalization";
import { useMutationGetRestaurants } from "../services/restaurants";
import {
  useCreateRestaurantsSuppliers,
  useDeactivateSupplier,
  useDeleteSupplier,
  useGetRestaurantsSupplier,
  usePatchBulkRestaurantsSuppliers,
} from "../services/suppliers";
import { getStatus } from "./Orders";
import RestaurantSupplierShow from "./restaurant-supplier/RestaurantSupplierShow";
import CatalogCSV from "./supplier/CatalogCSV";
import GetSupplierFiles from "./supplier/GetSupplierFiles";
import ProductsFromCatalogTable from "./supplier/ProductsFromCatalogTable";

const useStyles = makeStyles({
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Futura",
    fontWeight: "bold",
    textAlign: "center",
  },
  paragraph: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "center",
    flex: 1,
  },
  spacer: {
    flex: 1,
  },
  uploadButton: {
    background: "#000",
    fontWeight: "bold",
    alignSelf: "center",
    width: 272,
    height: 80,
    fontSize: 20,
    borderRadius: 5,
  },
});

const validateName = [required()];
const validateAddress = [required()];
const validateStatus = [required()];
const validateJob = [required()];
const validateReferred = [required()];

const postFilters = [
  <TextInput label="Buscar" source="search" alwaysOn style={{ flex: 1 }} />,
  <BooleanInput
    label="Catálogo Master"
    source="has_official_catalog"
    alwaysOn
    defaultValue={false}
  />,
];

export const SupplierList = ({ hasShow, hasCreate, ...props }) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField
          source="name"
          label="Nombre del proveedor"
          sortable={false}
        />
        <TextField source="address" label="Dirección" sortable={false} />
        <TextField
          source="contact_email"
          label="Correo de contacto"
          sortable={false}
          defaultValue="-"
        />
        <TextField
          source="contact_phone"
          label="Teléfono de contacto"
          sortable={false}
          defaultValue="-"
        />
        <BooleanField
          source="verified"
          label="Verificado"
          sortable={false}
          defaultValue="false"
        />
        <BooleanField
          source="is_available_for_chat"
          label="Chat disponible"
          sortable={false}
          defaultValue="false"
        />
        <BooleanField
          source="has_official_catalog"
          label="Catálogo master"
          defaultValue="false"
        />
        <DateField
          source="created_at"
          label="Creado el"
          sortable={false}
          defaultValue="-"
        />
        <ShowButton label="Ver" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};

export const currentEnvironment = () => {
  switch (process.env.REACT_APP_AUTH0_AUDIENCE) {
    case "production-admin-api":
      return "production";
    case "staging-admin-api":
      return "staging";
    case "development-admin-api":
      return "qa";
    default:
      return "qa";
  }
};

const PostShowActions = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <TextField
        source="name"
        label="Nombre"
        style={{ flex: 1, fontSize: 20, fontWeight: "bold" }}
      />
      <EditButton record={data} label={"Editar proveedor"} />
    </TopToolbar>
  );
};

const StatusTextField = ({ source }) => {
  const record = useRecordContext();
  if (record && record[source]) {
    return <span>{getStatus(record[source])}</span>;
  } else {
    return <></>;
  }
};

const SaveButton = ({ action, disabledBehavior }) => {
  const record = useRecordContext();
  if (record) {
    return (
      <button
        disabled={disabledBehavior}
        onClick={() =>
          action(
            record.id,
            record.name,
            record.contact_email,
            record.contact_phone
          )
        }
      >
        Guardar
      </button>
    );
  } else {
    return <></>;
  }
};

export const SupplierShow = (props) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [tableCSV, setTableCSV] = useState([]);
  const [tableCSVErrors, setTableCSVErrors] = useState([]);
  const [modifyCatalog, setModifyCatalog] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [isMasterCatalog, setIsMasterCatalog] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const { mutateAsync: restaurantsAsync } = useMutationGetRestaurants();
  const { mutate: createRelation } = useCreateRestaurantsSuppliers();
  const { mutate: patchBulkRelation } = usePatchBulkRestaurantsSuppliers();
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const filterOptions = async (options, filter) => {
    if (!filter) {
      return options;
    }
    // @ts-ignoreZ
    const restaurants = await restaurantsAsync(
      {
        limit: 20,
        page: 1,
        search: filter,
      },
      {}
    );
    return restaurants.data.map((res) => ({
      value: res.id,
      label: res.name,
    }));
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const download = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const GetPhotos = () => {
    const record = useRecordContext();

    if (!record || !record?.initial_catalog_pictures) return null;

    return (
      <Box style={{ flex: 1, flexDirection: "column" }}>
        <span>Fotos iniciales del proveedor</span>
        <div>
          {record?.initial_catalog_pictures.map((src, index) => (
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              width="300"
              key={index}
              style={{ margin: "2px" }}
              alt=""
            />
          ))}
        </div>

        {isViewerOpen && (
          <>
            <ImageViewer
              src={record?.initial_catalog_pictures}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{ backgroundColor: "#c3c3c399" }}
            />
            <div
              onClick={() =>
                download(record?.initial_catalog_pictures[currentImage])
              }
              data-title={"Descargar"}
              className={"RoundedButton"}
            />
          </>
        )}
      </Box>
    );
  };

  const handleBulkPriceChange = (tableIds, canSee) => {
    const ids = tableIds.map((tableId) => ({
      supplier_id: tableId.split("|")[0],
      restaurant_id: tableId.split("|")[1],
      can_see_products_prices: canSee,
    }));
    patchBulkRelation(ids, {
      onSuccess: () => {
        toast.success("Restaurantes actualizados exitosamente");
        refresh();
      },
    });
  };

  const handleIsMasterCatalogChange = (evt) => {
    setIsMasterCatalog(evt.target.checked);
  };

  const GetTitle = () => {
    const record = useRecordContext();

    if (!record) {
      return null;
    }

    return <span> - {record.name}</span>;
  };

  const handleSaveRelation = async (
    supplier_id,
    supplier_name,
    supplier_email,
    supplier_phone
  ) => {
    const restaurantId = selected[0].value;

    const relationFormdata = {
      can_see_products_prices: false,
      supplier_id,
      restaurant_id: restaurantId,
      contact_email: supplier_email,
      contact_phone: supplier_phone,
    };

    createRelation(relationFormdata, {
      onSuccess: () => {
        setSelected([]);
        toast.success("Restaurante agregado exitosamente");
        refresh();
      },
      onError: () => {
        toast.error("Algo sucedió");
      },
    });
  };

  const CanSeePricesBulkButton = ({ label, selectedIds, canSee }) => {
    return (
      <button onClick={() => handleBulkPriceChange(selectedIds, canSee)}>
        {label}
      </button>
    );
  };

  const DisableCanSeePricesBulkButton = ({ label, selectedIds, canSee }) => {
    return (
      <button onClick={() => handleBulkPriceChange(selectedIds, canSee)}>
        {label}
      </button>
    );
  };

  const ConnectBulkActionButtons = (props) => {
    return (
      <>
        <CanSeePricesBulkButton
          label="Habilitar precios"
          {...props}
          canSee={true}
        />
        <DisableCanSeePricesBulkButton
          label="Deshabilitar precios"
          {...props}
          canSee={false}
        />
      </>
    );
  };

  return (
    <Show actions={<PostShowActions />} title={"Proveedor"}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="fullWidth" scrollButtons="auto" />}
      >
        <Tab label="Órdenes">
          <ReferenceManyField
            reference="orders"
            target="id"
            label="Órdenes recientes"
            pagination={<Pagination />}
          >
            <Datagrid empty={<p>No existen órdenes para este proveedor</p>}>
              <TextField source="reference" label="Referencia" />
              <TextField source="__restaurant__.name" label="Restaurante" />
              <DateField
                source="created_at"
                label="Creado el"
                locales="es-ES"
                showTime={true}
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                }}
              />
              <StatusTextField source="status" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Conectar" path="connect">
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <span style={{ fontWeight: "bold", fontSize: 18, padding: 4 }}>
                Restaurantes actuales
              </span>
              <ReferenceManyField
                reference="restaurant-supplier-alt"
                target="id"
                label="Órdenes recientes"
                pagination={<Pagination />}
              >
                <Datagrid
                  empty={<p>No existen órdenes para este proveedor</p>}
                  rowClick="expand"
                  expand={<RestaurantSupplierShow refresh={refresh} />}
                  bulkActionButtons={<ConnectBulkActionButtons />}
                >
                  <TextField source="__restaurant__.name" label="Restaurante" />
                </Datagrid>
              </ReferenceManyField>
            </div>
            <div style={{ flex: 1, padding: "2rem" }}>
              <div style={{ flexDirection: "column", height: 350 }}>
                <div style={{ marginBottom: 8 }}>
                  Busca a continuación el restaurante que deseas agregar
                </div>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  filterOptions={filterOptions}
                  debounceDuration={300}
                  hasSelectAll={false}
                  overrideStrings={MultiSelectLocalization}
                  disabled={selected.length}
                  ClearIcon={<></>}
                  ClearSelectedIcon={<></>}
                  closeOnChangedValue
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: "3rem",
                  }}
                >
                  <button
                    disabled={!selected.length}
                    onClick={() => setSelected([])}
                  >
                    Limpiar selección
                  </button>
                  <SaveButton
                    action={handleSaveRelation}
                    disabledBehavior={!selected.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab label="Catálogo" path="products" title={<GetTitle />}>
          {!modifyCatalog ? (
            <>
              {showFiles ? (
                <>
                  <Box my={2} display={"flex"}>
                    <Button
                      variant="outlined"
                      onClick={() => setShowFiles(false)}
                      startIcon={<BackIcon />}
                    >
                      Volver al catálogo actual
                    </Button>
                  </Box>
                  <Box style={{ flex: 1 }}>
                    <GetSupplierFiles />
                  </Box>
                </>
              ) : (
                <Box display={"flex"} flexDirection={"row-reverse"}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowFiles(true)}
                    startIcon={<AttachFileIcon />}
                  >
                    Ver Archivos
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <></>
          )}

          {!showFiles ? (
            <>
              {!modifyCatalog ? (
                <>
                  <Box my={2} display={"flex"} flexDirection={"row-reverse"}>
                    <Button
                      variant="outlined"
                      onClick={() => setModifyCatalog(true)}
                      startIcon={<EditIcon />}
                    >
                      Modificar catálogo
                    </Button>
                  </Box>
                  <ProductsFromCatalogTable
                    tableCSV={tableCSV}
                    tableCSVErrors={tableCSVErrors}
                    refresh={refresh}
                  />
                </>
              ) : (
                <>
                  <Box my={2} display={"flex"}>
                    <Button
                      variant="outlined"
                      onClick={() => setModifyCatalog(false)}
                      startIcon={<BackIcon />}
                    >
                      Volver al catálogo actual
                    </Button>
                  </Box>
                  <Box
                    style={{
                      flex: 1,
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    {!tableCSV && (
                      <>
                        <Typography
                          variant="h6"
                          color="inherit"
                          className={classes.title}
                          children={"Subir Lista de Productos"}
                        />
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          className={classes.paragraph}
                          children={`Tenemos 7 restaurantes esperando por el catálogo.`}
                        />
                      </>
                    )}
                    <CatalogCSV
                      action={setTableCSV}
                      isMasterCatalog={isMasterCatalog}
                      actionHandleError={setTableCSVErrors}
                    />
                    <Box marginTop={5}>
                      <FormGroup
                        onChange={handleIsMasterCatalogChange}
                        style={{
                          flex: 1,
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox checked={isMasterCatalog} />}
                          label="¿Es el Catálogo Master?"
                        />
                      </FormGroup>
                    </Box>
                    {Boolean(tableCSV.length) && (
                      <ProductsFromCatalogTable
                        tableCSV={tableCSV}
                        tableCSVErrors={tableCSVErrors}
                      />
                    )}
                  </Box>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Tab>
        <Tab label="Equipos" path="teams">
          <span>Equipos</span>
        </Tab>
        <Tab label="Invitaciones" path="invitations">
          <ReferenceManyField
            reference="restaurant-invitations"
            target="id"
            label="Órdenes recientes"
            pagination={<Pagination />}
          >
            <Datagrid
              empty={
                <p>
                  No existen invitaciones de restaurantes para este proveedor
                </p>
              }
            >
              <TextField source="restaurant_name" label="Restaurante" />
              <TextField source="contact_name" label="Nombre de contacto" />
              <TextField
                source="phone_number"
                label="Teléfono de contacto"
                emptyText={"--"}
              />
              <TextField
                source="email"
                label="Correo de contacto"
                emptyText={"--"}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Restaurantes" path="restaurants">
          <ArrayField source="restaurants" label="Restaurantes">
            <Datagrid onToggleItem={() => null}>
              <TextField source="id" />
              <TextField source="name" label="Nombre" />
              <TextField source="address" label="Dirección" />
            </Datagrid>
          </ArrayField>
          );
        </Tab>
        <Tab label="Información" path="information">
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Box style={{ flex: 1 }}>
              <span>Información</span>
              <SimpleShowLayout>
                <TextField source="id" label="id" />
                <TextField source="name" label="Nombre" />
                <TextField source="contact_email" label="Correo de contacto" />
                <TextField
                  source="contact_phone"
                  label="Teléfono de contacto"
                />
                <TextField source="address" label="Dirección" />
                <DateField
                  label="Fecha de creación"
                  source="created_at"
                  locales="es-ES"
                  options={{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }}
                />
                <DateField
                  label="Última actualización"
                  source="updated_at"
                  locales="es-ES"
                  options={{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }}
                />
                <BooleanField source="verified" label="Verificado" />
                <BooleanField
                  source="is_available_for_chat"
                  label="Chat disponible"
                />
                <BooleanField
                  source="has_official_catalog"
                  label="Catálogo master"
                />
              </SimpleShowLayout>
            </Box>
            <GetPhotos />
          </Box>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const SupplierCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateName} />
      <TextInput source="address" validate={validateAddress} />
      <TextInput source="status" validate={validateStatus} />
      <TextInput source="job" validate={validateJob} />
      <TextInput source="referred_by" validate={validateReferred} />
    </SimpleForm>
  </Create>
);

export const SupplierEdit = (props) => {
  const redirect = useRedirect();
  const [isOpen, setIsOpen] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: getRestaurantsSupplier } = useGetRestaurantsSupplier();
  const { mutateAsync: deleteSupplier } = useDeleteSupplier();
  const { mutate: deactivateSupplier } = useDeactivateSupplier();

  const handleDeleteSupplier = () => {
    setIsLoading(true);
    deactivateSupplier(
      { supplier_id: currentSupplier.id },
      {
        onSuccess: () => {
          getRestaurantsSupplier(
            { supplier_id: currentSupplier.id },
            {
              onSuccess: (res) => {
                if (res.data && res.data.length) {
                  res.data.forEach(async (val, index) => {
                    if (index === res.data.length - 1) {
                      setTimeout(async () => {
                        await deleteSupplier(
                          { supplier_id: currentSupplier.id },
                          {
                            onSuccess: (res) => {
                              if (!res.error) {
                                toast.success(
                                  "Proveedor eliminado exitosamente"
                                );
                                redirect("list", "suppliers");
                              } else {
                                toast.error(
                                  "No se pudo eliminar al proveedor, existen órdenes para este proveedor"
                                );
                              }
                            },
                            onError: () => {
                              toast.error(
                                "Ha ocurrido un error eliminando al proveedor"
                              );
                            },
                            onSettled: () => {
                              setIsLoading(false);
                              setIsOpen(false);
                            },
                          }
                        );
                      }, (res.data.length - 1) * 2000 + 1000);
                    }
                  });
                }
                if (res.data && res.data.length === 0) {
                  setTimeout(async () => {
                    await deleteSupplier(
                      { supplier_id: currentSupplier.id },
                      {
                        onSuccess: (res) => {
                          if (!res.error) {
                            toast.success("Proveedor eliminado exitosamente");
                            redirect("list", "suppliers");
                          } else {
                            toast.error(
                              "No se pudo eliminar al proveedor, existen órdenes para este proveedor"
                            );
                          }
                        },
                        onError: () => {
                          toast.error(
                            "Ha ocurrido un error eliminando al proveedor"
                          );
                        },
                        onSettled: () => {
                          setIsLoading(false);
                          setIsOpen(false);
                        },
                      }
                    );
                  }, (res.data.length - 1) * 2000 + 1000);
                }
              },
              onError: () => {
                toast.error(
                  "Ha ocurrido un error obteniendo la relación restaurantes proveedores"
                );
                setIsLoading(false);
              },
            }
          );
        },
        onError: () => {
          toast.error("Ha ocurrido un error desactivando este proveedor");
          setIsLoading(false);
        },
      }
    );
  };

  const handleDeleteSupplierDialog = () => {
    setIsOpen(true);
  };

  const handleDeleteSupplierDialogClose = () => {
    setIsOpen(false);
  };

  const RemoveSupplierButton = ({ label }) => {
    const record = useRecordContext();
    setCurrentSupplier(record);
    return <button onClick={handleDeleteSupplierDialog}>{label}</button>;
  };

  const CustomToolbar = (props) => {
    return (
      <Toolbar
        {...props}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <DefaultSaveButton label={"Guardar"} />
        <RemoveSupplierButton label={"ELIMINAR"} />
      </Toolbar>
    );
  };

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="id" disabled />
        <BooleanInput source="active" label="Activo" />
        <BooleanInput source="verified" label="Verificado" />
        <BooleanInput source="is_available_for_chat" label="Chat disponible" />
        <BooleanInput source="has_official_catalog" label="Catálogo master" />
        <TextInput source="address" label="Dirección" />
        <TextInput source="contact_email" label="Correo" />
        <TextInput source="contact_phone" label="Teléfono" />
        <TextInput source="name" label="Nombre" />
        <Confirm
          isOpen={isOpen}
          loading={isLoading}
          title="Eliminar proveedor"
          content={
            isLoading
              ? "Por favor espere, este proceso puede llegar a tomar un tiempo considerable"
              : "¿Seguro que deseas eliminar este proveedor?"
          }
          onConfirm={handleDeleteSupplier}
          onClose={handleDeleteSupplierDialogClose}
          confirm={"Eliminar"}
          cancel={"No"}
        />
      </SimpleForm>
    </Edit>
  );
};
