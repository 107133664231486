export default {
    "allItemsAreSelected": "Todos las opciones han sido seleccionadas",
    "clearSearch": "Limpiar búsqueda",
    "clearSelected": "Limpiar selecciones",
    "noOptions": "Sin opciones para mostrar",
    "search": "Buscar",
    "selectAll": "Seleccionar todas las opciones",
    "selectAllFiltered": "Seleccionar todas las opciones (Filtradas)",
    "selectSomeItems": "Seleccione una opción...",
    "create": "Crear",
}
