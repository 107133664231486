import React, { useEffect, useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  SaveButton,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { Grid } from "@material-ui/core";

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = { [source]: record };
  }
  return (
    <ImageField
      record={record}
      source={source}
      sx={{
        "& img": {
          minWidth: 400,
          minHeight: 400,
          maxWidth: 400,
          maxHeight: 400,
          objectFit: "fill",
        },
      }}
    />
  );
};

const ProductImageInput = ({ setLoading }) => {
  const formContext = useFormContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleImageUpload = async (file) => {
    setLoading(true);
    dataProvider
      .uploadProductImage("file-uploads", { file })
      .then((response) => {
        const { original, thumb, medium } = response?.data;
        formContext.setValue("original_url", original);
        formContext.setValue("thumb_url", thumb);
        formContext.setValue("medium_url", medium);
        setLoading(false);
      })
      .catch((error) => {
        notify("Error al subir la imagen:" + error, "warning");
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <h4>Imagen:</h4>
      <ImageField
        source="product_thumbnail.original_url"
        sx={{
          "& img": {
            minWidth: 400,
            minHeight: 400,
            maxWidth: 400,
            maxHeight: 400,
            objectFit: "fill",
          },
        }}
      />
      <ImageInput
        source="preview"
        label="Subir imagen de producto"
        placeholder={<p>Arraste la foto de su producto o busque el archivo</p>}
        sx={{ width: 400 }}
        onChange={handleImageUpload}
      >
        <PreviewImage source="src" />
      </ImageInput>
    </>
  );
};

const CustomToolbar = ({ loading }) => (
  <Toolbar>
    <SaveButton disabled={loading} />
  </Toolbar>
);

export const ProductsList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <ImageField
          source="product_thumbnail.thumb_url"
          title="name"
          label="Imagen"
        />
        <TextField source="sku" label="SKU" sortable={false} />
        <TextField source="name" label="Nombre del producto" sortable={false} />
        <TextField source="unit" label="Unidad" sortable={false} />
        <TextField
          source="product_category_id"
          label="Categoría"
          sortable={false}
        />
        <DateField source="created_at" label="Creado el" sortable={false} />
        <DateField
          source="updated_at"
          label="Actualizado el"
          sortable={false}
        />
        <ShowButton label="Ver" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};

export const ProductsEdit = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar loading={loading} />}>
        <Grid container spacing={6}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <h4>Detalles del producto:</h4>
            <TextInput source="id" readOnly />
            <TextInput source="name" label="Nombre" />
            <TextInput source="unit" label="Unidad" readOnly />
            <TextInput
              source="currency_code"
              label="Moneda"
              defaultValue="CRC"
            />
            <TextInput source="sku" label="SKU" />
            <NumberInput source="price" label="Precio" />
            <NumberInput source="stock" label="Stock" />
            <TextInput source="brand" label="Marca" />
            <NumberInput source="supplier_id" label="Id del proveedor" />
            <NumberInput source="product_category_id" label="Id de categoría" />
          </Grid>
          <Grid item md={6}>
            <ProductImageInput setLoading={setLoading} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const ProductsCreate = (props) => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const [productUnits, setProductUnits] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getList("product-units", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "name", order: "ASC" },
      })
      .then(({ data }) => {
        setProductUnits(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error... {error}</p>;
  if (!productUnits) return <p>Error loading product units...</p>;

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nombre" />
        <SelectInput source="unit" label="Unidad" choices={productUnits} />
        <TextInput source="currency_code" label="Moneda" defaultValue="CRC" />
        <TextInput source="sku" label="SKU" />
        <TextInput source="price" label="Precio" />
        <TextInput source="brand" label="Marca" />
        <NumberInput source="supplier_id" label="Id del proveedor" />
        <NumberInput source="product_category_id" label="Id de categoría" />
      </SimpleForm>
    </Create>
  );
};

export const ProductsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nombre" />
      <TextField source="unit" label="Unidad" />
      <TextField source="currency_code" label="Moneda" defaultValue="CRC" />
      <TextField source="sku" label="SKU" />
      <TextField source="price" label="Precio" />
      <TextField source="stock" label="Stock" />
      <TextField source="brand" label="Marca" />
      <TextField source="supplier_id" label="Id del proveedor" />
      <TextField source="product_category_id" label="Id de categoría" />
    </SimpleShowLayout>
  </Show>
);
