const authProvider = ({
                          isAuthenticated,
                          loginWithRedirect,
                          logout,
                          user,
                          isLoading,
                          handleRedirectCallback,
                      }) => ({
    login: (url) =>  typeof url === 'undefined' ? loginWithRedirect : handleRedirectCallback(url.location),
    logout: () =>  isAuthenticated ? logout({ returnTo: window.location.origin, federated: true }) :  Promise.resolve(),
    checkError: () => Promise.resolve(),
    checkAuth: () => (isAuthenticated || isLoading ? Promise.resolve() : Promise.reject()),
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: () =>
        Promise.resolve({
            id: user.id,
            fullName: user.name,
            avatar: user.picture,
        }),
});

export default authProvider;
