import React, { useCallback, useState } from "react";
import {
  BooleanInput,
  Confirm,
  Form,
  SaveButton,
  TextField,
  TextInput,
} from "react-admin";
import ImageViewer from "react-simple-image-viewer";
import { Grid, CardContent, Typography, Card, Box } from "@mui/material";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as SuppliersService from "../../services/suppliers";
import { FavoriteProductsPanel } from "./FavoriteProductsPanel";

const useStyles = makeStyles({
  uploadButton: {
    background: "#ff7961",
    fontWeight: "bold",
    alignSelf: "center",
    height: 35,
    borderRadius: 5,
  },
});

const RestaurantSupplierShow = (props) => {
  const { record, refresh } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: updateRelation, isLoading: isUpdating } =
    SuppliersService.useUpdateRestaurantsSuppliers();
  const { mutate: deleteRelation, isLoading: isDeleting } =
    SuppliersService.useDeleteRestaurantsSuppliers();
  const classes = useStyles();

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const download = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDeleteRelation = () => {
    const { restaurant_id, supplier_id } = record;
    deleteRelation(
      { restaurant_id, supplier_id },
      {
        onSuccess: () => {
          toast.success("Relación borrada exitosamente");
          refresh();
        },
        onError: () => {
          toast.error("Algo sucedió");
        },
      }
    );
  };

  const handleDeleteDialog = () => {
    setIsOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = (data) => {
    updateRelation(
      {
        contact_phone: data["__point_of_contact__"]["phone_number"] || "",
        contact_email: data["__point_of_contact__"]["email"] || "",
        contact_first_name: data["__point_of_contact__"]["first_name"] || "",
        contact_last_name: data["__point_of_contact__"]["last_name"] || "",
        restaurant_id: data["restaurant_id"],
        supplier_id: data["supplier_id"],
        can_see_products_prices: data["can_see_products_prices"],
      },
      {
        onSuccess: () => {
          toast.success("Punto de contacto modificado exitosamente");
        },
        onError: () => {
          toast.error(
            "No se pudo modificar el punto de contacto. Intente nuevamente"
          );
        },
      }
    );
  };

  const GetPhotos = () => {
    if (!record || !record?.catalog_images_urls) return null;

    return (
      <Box style={{ flex: 1, flexDirection: "column" }}>
        <span>Fotos iniciales del proveedor</span>
        <div>
          {record?.catalog_images_urls.map((src, index) => (
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              width="300"
              key={index}
              style={{ margin: "2px" }}
              alt=""
            />
          ))}
        </div>

        {isViewerOpen && (
          <>
            <ImageViewer
              src={record?.catalog_images_urls}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{ backgroundColor: "#c3c3c399" }}
            />
            <div
              onClick={() =>
                download(record?.catalog_images_urls[currentImage])
              }
              data-title={"Descargar"}
              className={"RoundedButton"}
            />
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      {Boolean(record) && Boolean(record.catalog_images_urls) && (
        <>
          <span style={{ fontWeight: "bold", fontSize: 14, padding: 4 }}>
            Catálogo de imágenes
          </span>
          <GetPhotos />
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} xl={5}>
          <Card sx={{ width: "100%", my: 2 }}>
            <Form onSubmit={handleFormSubmit}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Código del cliente
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom align="right">
                      Puede ver precios
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} container>
                    <TextField
                      reference="restaurant-supplier-alt"
                      source="client_code"
                      emptyText={"Sin código"}
                    />
                  </Grid>
                  <Grid item xs={6} container justifyContent={"flex-end"}>
                    <BooleanInput
                      reference="restaurant-supplier-alt"
                      source="can_see_products_prices"
                      align="right"
                      label={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={4}>
                  <Grid item xs={12} align="center">
                    <Typography variant="subtitle1" gutterBottom>
                      Información de contacto
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      source="__point_of_contact__.email"
                      label="Correo de contacto"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      source="__point_of_contact__.phone_number"
                      label="Teléfono de contacto"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      source="__point_of_contact__.first_name"
                      label="Nombre de contacto"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      source="__point_of_contact__.last_name"
                      label="Apellido de contacto"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} align="left">
                    <Button
                      className={classes.uploadButton}
                      onClick={handleDeleteDialog}
                    >
                      Eliminar relación
                    </Button>
                    <Confirm
                      isOpen={isOpen}
                      loading={isDeleting}
                      title="Borrar relación"
                      content={
                        "¿Está seguro que desea borrar esta relación restaurante-proveedor? (Se eliminará el chat entre ambos)"
                      }
                      onConfirm={handleDeleteRelation}
                      onClose={handleDeleteDialogClose}
                      confirm={"Confirmar"}
                      cancel={"Cancelar"}
                    />
                  </Grid>
                  <Grid item xs={6} align="right">
                    <SaveButton label={"guardar"} />
                  </Grid>
                </Grid>
              </CardContent>
            </Form>
          </Card>
        </Grid>
        <Grid item xs={12} xl={7}>
          <FavoriteProductsPanel record={record} />
        </Grid>
      </Grid>
    </>
  );
};

export default RestaurantSupplierShow;
