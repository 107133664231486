export function getApiUrl(endpoint, queryParams) {
  const url = new URL(`${process.env.REACT_APP_API_URL}${endpoint}/`);

  if (!queryParams) {
    return url.toString();
  }

  Object.keys(queryParams).forEach((key) => {
    url.searchParams.append(key, queryParams[key]);
  });

  const urlString = url.toString();

  return urlString;
}

export default getApiUrl;
