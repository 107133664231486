import { Typography } from "@material-ui/core";
import React from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { toast } from "react-toastify";

export const getStatus = (status) => {
  switch (status) {
    case "approved":
      return "Confirmado";
    case "rejected_by_supplier":
      return "Rechazado";
    case "canceled_by_supplier":
      return "Cancelado";
    case "rejected_by_restaurant":
      return "Rechazado";
    case "canceled_by_restaurant":
      return "Cancelado";
    case "created":
      return "Pendiente";
    default:
      return "Desconocido";
  }
};

/*
  The following statuses are also possible but not present in getStatus:
  initial: "Inicial",
  invoiced: "Facturado",
  waiting_for_payment: "Esperando pago",
  paid: "Pagado",
  shipped: "Envíado",
  delivered: "Entregado",
  completed: "Completado",
  */
const statusFilterLabels = {
  approved: "Confirmado",
  canceled_by_restaurant: "Cancelado por restaurante",
  canceled_by_supplier: "Cancelado por proveedor",
  created: "Pendiente",
  rejected_by_supplier: "Rechazado",
};

const statusChoices = Object.entries(statusFilterLabels).map(([id, name]) => ({
  id,
  name,
}));

const StatusFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="status" choices={statusChoices} alwaysOn resettable />
  </Filter>
);

const StatusTextField = ({ source }) => {
  const record = useRecordContext();
  if (record && record[source]) {
    return <span>{getStatus(record[source])}</span>;
  } else {
    return <></>;
  }
};

const postFilters = [
  <TextInput label="Buscar" source="search" alwaysOn />,
  <StatusFilter label="Estado" />,
];

const CopyLinkButton = () => {
  const record = useRecordContext();

  const supplierEnvironment = () => {
    switch (process.env.REACT_APP_AUTH0_AUDIENCE) {
      case "production-admin-api":
        return "supplier";
      case "staging-admin-api":
        return "supplier-staging";
      case "development-admin-api":
        return "supplier-qa";
      default:
        return "supplier-qa";
    }
  };

  const handleCopyLink = (id, approvalToken) => {
    const el = document.createElement("textarea");
    el.value = `https://${supplierEnvironment()}.usealisto.com/confirm/${id}/${approvalToken}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Enlace copiado al portapapeles");
  };

  if (record) {
    return (
      <button onClick={() => handleCopyLink(record.id, record.approval_token)}>
        Copiar enlace anónimo
      </button>
    );
  } else {
    return <></>;
  }
};

const OrderLink = () => {
  const record = useRecordContext();
  const supplierEnvironment = () => {
    switch (process.env.REACT_APP_AUTH0_AUDIENCE) {
      case "production-admin-api":
        return "supplier";
      case "staging-admin-api":
        return "supplier-staging";
      case "development-admin-api":
        return "supplier-qa";
      default:
        return "supplier-qa";
    }
  };

  if (record) {
    return (
      <a
        target="_blank"
        href={`https://${supplierEnvironment()}.usealisto.com/confirm/${
          record.id
        }/${record.approval_token}`}
        rel="noreferrer"
      >
        {record.id}
      </a>
    );
  } else {
    return <></>;
  }
};

const CreatedAtField = () => (
  <DateField
    source="created_at"
    label="Creado el"
    locales="es-ES"
    showTime={true}
    options={{
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    }}
  />
);

const DeliveryDateField = () => (
  <DateField
    source="delivery_date"
    locales="es-ES"
    showTime={false}
    options={{
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      timeZone: "UTC",
    }}
  />
);

const SeenByProviderField = () => (
  <DateField
    source="supplier_viewed_at"
    locales="es-ES"
    showTime={true}
    options={{
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    }}
  />
);

export const SupplierLink = () => {
  const record = useRecordContext();
  if (record && record.__supplier__) {
    return (
      <a href={`#/suppliers/${record.supplier_id}/show`}>
        {record.__supplier__.name}
      </a>
    );
  } else {
    return <></>;
  }
};

export const OrdersList = (props) => {
  return (
    <List
      {...props}
      filters={postFilters}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <OrderLink source="id" label="Orden" />
        <TextField source="__restaurant__.name" label="Restaurante" />
        <SupplierLink label="Proveedor" />
        <TextField source="reference" label="Referencia" />
        <CreatedAtField source="created_at" label="Creado el" />
        <DeliveryDateField label="Fecha de entrega" />
        <SeenByProviderField label="Visto por proveedor" />
        <StatusTextField source="status" label="Estado" />
        <CopyLinkButton />
        <ShowButton label="Ver" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};

export const OrdersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="reference" disabled />
      <TextInput source="status" />
    </SimpleForm>
  </Edit>
);

export const OrdersShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout
      {...props}
      tabs={<TabbedShowLayoutTabs variant="fullWidth" scrollButtons="auto" />}
    >
      <Tab label="General" role="tab">
        <Typography variant="h5" gutterBottom>
          Información de pedido
        </Typography>
        <StatusTextField source="status" label="Estado" />
        <TextField source="id" label="Pedido id" />
        <TextField source="supplier_name" label="Proveedor" />
        <TextField source="restaurant_name" label="Restaurante" />
        <TextField source="reference" label="Referencia" />
        <TextField source="new_delivery_address" label="Dirección de entrega" />
        <TextField
          source="delivery_instructions"
          label="Instrucciones de entrega"
        />
        <CreatedAtField label="Fecha de creación" />
        <DeliveryDateField label="Fecha de entrega" />
        <SeenByProviderField label="Visto por proveedor" />
      </Tab>
      <Tab label="Detalles" role="tab">
        <Typography variant="h5" gutterBottom>
          Detalles de pedido
        </Typography>
        <ArrayField source="order_lines">
          <Datagrid>
            <TextField source="product.sku" label="SKU" />
            <TextField source="product.name" label="Producto" />
            <NumberField source="quantity" label="Cantidad" />
            <TextField source="product.price" label="Precio" />
            <BooleanField source="review.itemStatus" label="Estatus revisión" />
            <TextField
              source="review.itemComment"
              label="Comentario revisión"
            />
          </Datagrid>
        </ArrayField>
        <TextField source="review.comment" label="Comentario de revisión" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
