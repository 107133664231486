import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: apiURL });

export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  axiosInstance.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    config.headers.Authorization = `${token}`;
    config.headers["access-control-allow-origin"] = "*";
    config.headers["Tenant"] = "admin";
    return config;
  });
};

axiosInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      console.log("401 error");
    }

    console.log("Error Response", error.response);

    return Promise.reject(error.response.data);
  }
);

export default axiosInstance;
