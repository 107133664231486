import axiosInstance from "./api";
import Endpoints from "../constants/Endpoints";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

interface GetRestaurantsFormData {
  limit: number;
  page: number;
  search?: string;
}

interface DeactivateRestaurantFormData {
  restaurant_id: number;
}

const _getRestaurants = async (
  formData: GetRestaurantsFormData
): Promise<any> => {
  const { limit, page, search } = formData;
  try {
    const response: any = (
      await axiosInstance.get(`${Endpoints.RESTAURANT_GET}`, {
        params: {
          limit,
          page,
          search,
        },
      })
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useMutationGetRestaurants = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (getRestaurantsFormData) => _getRestaurants(getRestaurantsFormData),
    options
  );
};

export const getRestaurantUsers = async (restaurantId) => {
  try {
    const response = (
      await axiosInstance.get(`${Endpoints.RESTAURANT_USERS}`, {
        params: {
          "filter.restaurant_id": restaurantId,
        },
      })
    ).data;
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

const _deactivateRestaurant = async (
  formData: DeactivateRestaurantFormData
): Promise<any> => {
  try {
    const response: any = (
      await axiosInstance.patch(
        `${Endpoints.RESTAURANT_DEACTIVATE}`.replace(
          "{id}",
          formData.restaurant_id
        )
      )
    ).data;
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const useDeactivateRestaurant = (
  options?: UseMutationOptions
): UseMutationResult => {
  return useMutation(
    (deactivateRestaurantFormData: DeactivateRestaurantFormData) =>
      _deactivateRestaurant(deactivateRestaurantFormData),
    options
  );
};
