import React, { useEffect, useState } from "react";
import { useGetSupplierFiles } from "../../services/suppliers";

export const GetSupplierFiles = () => {
  const [supplier_id, setSupplierId] = useState(null);
  const { data, error, isLoading } = useGetSupplierFiles(supplier_id);

  useEffect(() => {
    const supplierIdFromHash = window.location.hash.match(/\/suppliers\/(\d+)/);

    if (supplierIdFromHash) {
      setSupplierId(supplierIdFromHash[1]);
    }
  }, []);

  const formatDate = (uploadTime) => {
    const date = new Date(uploadTime);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const formattedDate = date.toLocaleDateString("es-ES", options);

    return formattedDate;
  };

  if (isLoading) {
    return <p>Cargando archivos...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!data || data.length === 0) {
    return <p>No hay archivos disponibles.</p>;
  }

  const sortedData = [...data].sort((a, b) => b.upload_time - a.upload_time);

  return (
    <ul style={{ listStyle: "none" }}>
      {sortedData.reverse().map((file) => (
        <li key={file.id}>
          <div>
            <a href={file.url} download={file.filename}>
              {file.filename}
            </a>
          </div>
          <div>Subido el día: {formatDate(file.upload_time)}</div>
          <strong>ID: {file.id}</strong>
        </li>
      ))}
    </ul>
  );
};

export default GetSupplierFiles;
