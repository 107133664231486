import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
} from "react-admin";

export const SupplierInvitations = ({ ...props }) => {
  const postFilters = [
    <TextInput label="Buscar" source="search" alwaysOn />,
    <BooleanInput
      label="Onboarded"
      source="onboarded"
      alwaysOn
      defaultValue={false}
    />,
    <BooleanInput
      label="Sin restaurantes asignados"
      source="only_unassigned_to_restaurant"
      alwaysOn
      defaultValue={false}
    />,
  ];

  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField
          source="name"
          label="Nombre del proveedor"
          sortable={false}
        />
        <TextField
          source="__invited_by_restaurant__.name"
          label="Invitado por restaurante"
          sortable={false}
        />
        <TextField
          source="contact_email"
          label="Correo de contacto"
          sortable={false}
          defaultValue="-"
        />
        <TextField
          source="contact_phone"
          label="Teléfono de contacto"
          sortable={false}
          defaultValue="-"
        />
        <BooleanField
          source="onboarded"
          label="Onboarded"
          sortable={false}
          defaultValue="false"
        />
        <DateField
          source="created_at"
          label="Creado el"
          sortable={false}
          defaultValue="-"
        />
      </Datagrid>
    </List>
  );
};
