import axiosInstance from "./api";
import Endpoints from "../constants/Endpoints";
import type {UseQueryOpts} from "../models/queryTypes";
import {useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult} from "react-query";
import {QueryTypes} from "../models/queryTypes";

interface GetAppSettingsResponse {
    "qa_env_restaurants_app_version": string | null,
    "staging_env_restaurants_app_version": string | null,
    "production_env_restaurants_app_version": string | null
}

interface UpdateAppSettingsFormData {
    "qa_env_restaurants_app_version": string,
    "staging_env_restaurants_app_version": string,
    "production_env_restaurants_app_version": string
}

const _getGetAppVersion = async () => {
    try {
        const response = (
            await axiosInstance.get(`${Endpoints.APP_SETTINGS}`)
        ).data;

        return response;
    } catch (error) {
        throw new Error(`Unknown error: ${error}`);
    }
};

export const useGetAppVersions = ({ refetchOnFocus, ...opts }: UseQueryOpts): UseQueryResult => {
    return useQuery(QueryTypes.APP_VERSION, _getGetAppVersion, {
        cacheTime: 0,
        ...opts,
    });
};

const _updateAppVersion = async (
    formData: UpdateAppSettingsFormData
) => {
    try{
        const response = (
            await axiosInstance.patch(`${Endpoints.APP_SETTINGS}`, formData)
        ).data;

        return response || null;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const useUpdateAppVersion = (
    options?: UseMutationOptions
): UseMutationResult => {
    return useMutation(
        (updateAppSettingsFormData) =>
            _updateAppVersion(updateAppSettingsFormData),
        options
    );
};
