import React, { useState, useEffect } from "react";
import {
  Datagrid,
  FunctionField,
  Pagination,
  TextField,
  useDataProvider,
  useGetList,
  useNotify,
} from "react-admin";
import { Button } from "@material-ui/core";
import { TextField as MUITextField } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

const AddToFavoritesButton = ({
  label,
  restaurantId,
  record,
  favorites,
  refresh,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleClick = async () => {
    try {
      await dataProvider.create("favorite-products", {
        data: { product_ids: [record.id], restaurant_id: restaurantId },
      });
      notify("Producto agregado a lista de favoritos");
    } catch (error) {
      if (error.message) {
        notify(`Error: ${error.message}`, "warning");
      } else {
        notify(
          "Ocurrió un error intentando agregar el producto a la lista",
          "warning"
        );
      }
    }
    refresh();
  };

  if (favorites.includes(record.id)) {
    return <CheckIcon />;
  }

  return (
    <Button
      size="small"
      onClick={handleClick}
      style={{ textTransform: "none" }}
    >
      {label}
    </Button>
  );
};

const DeleteFavoriteButton = ({ record, restaurantId, refresh }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = () => {
    dataProvider
      .delete("favorite-products", {
        id: record.id,
        data: {
          product_ids: [record.id],
          restaurant_id: restaurantId,
        },
      })
      .then(() => {
        refresh();
        notify("Producto eliminado de la lista de favoritos", "info");
      })
      .catch((error) => {
        notify(`Error: ${error.response?.data?.message}`, "warning");
      });
  };

  return (
    <IconButton aria-label="delete" size="small" onClick={handleClick}>
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  );
};

const SupplierCatalog = ({ supplierId, restaurantId, favorites, refresh }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const { data, total, loading, error } = useGetList(
    "supplier-catalog",
    {
      filter: { supplier_id: supplierId, search: searchTerm },
      pagination: { page, perPage },
    },
    { field: "id", order: "ASC" }
  );

  useEffect(() => {
    setPage(1);
  }, [supplierId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const ids = data ? Object.values(data).map((record) => record.id) : [];
  return (
    <div my="2">
      <h3>Catálogo:</h3>

      <MUITextField
        id="filled-basic"
        label="Buscar"
        variant="filled"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Datagrid
        data={data}
        ids={ids}
        pagination={
          <Pagination
            page={page}
            perPage={perPage}
            total={total}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        }
      >
        <TextField source="name" />
        <TextField source="sku" />
        <TextField source="id" />

        <FunctionField
          label="Lista"
          render={(record) => {
            return (
              <AddToFavoritesButton
                label="Agregar a lista"
                restaurantId={restaurantId}
                record={record}
                favorites={favorites}
                refresh={refresh}
              />
            );
          }}
        />
      </Datagrid>
    </div>
  );
};

export const FavoriteProductsPanel = ({ record }) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [refresh, setRefresh] = useState(false);

  const supplierId = record.supplier_id;
  const restaurantId = record.restaurant_id;

  useEffect(() => {
    dataProvider
      .getMany("favorite-products", {
        supplier_id: supplierId,
        restaurant_id: record.restaurant_id,
        pagination: {
          page: 1,
        },
        sort: {
          field: "name",
          order: "ASC",
        },
      })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, record, supplierId, refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error: {error.message}</span>;
  if (!data) return <span>No data</span>;

  return (
    <div>
      <h3>Productos en la lista:</h3>
      <Datagrid data={data} ids={data.map((item) => item.id)}>
        <TextField source="name" label="producto" />
        <TextField source="sku" />
        <TextField source="id" />

        <FunctionField
          label="Acciones"
          render={(record) => (
            <DeleteFavoriteButton
              record={record}
              restaurantId={restaurantId}
              refresh={handleRefresh}
            />
          )}
        />
      </Datagrid>
      <SupplierCatalog
        supplierId={supplierId}
        restaurantId={restaurantId}
        favorites={data.map((item) => item.id)}
        refresh={handleRefresh}
      />
    </div>
  );
};
