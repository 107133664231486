import React, { useState } from "react";
import { useCSVReader } from "react-papaparse";
import { useUploadFile, useUploadMasterCatalog } from "../../services/products";
import { useUpdateHasMasterCatalog } from "../../services/suppliers";
import { useRecordContext } from "react-admin";
import { Alert, Snackbar } from "@mui/material";

const CatalogCSV = ({ action, isMasterCatalog, actionHandleError }) => {
  const record = useRecordContext();
  const { CSVReader } = useCSVReader();
  const [open, setOpen] = useState(false);

  const { mutate: uploadFile } = useUploadFile();
  const { mutate: uploadMasterCatalog } = useUploadMasterCatalog();
  const { mutate: updateHasMasterCatalog } = useUpdateHasMasterCatalog();

  const openSnackbar = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const createCSVData = (
    name,
    code,
    unit,
    price,
    sku,
    stock,
    brand,
    product_category,
    available
  ) => {
    return {
      name,
      code,
      unit,
      price,
      sku,
      stock,
      brand,
      product_category,
      available,
    };
  };

  const mapToTable = (csvFile: (string | number)[]) => {
    return csvFile.slice(1).map((csvData: any) => {
      return createCSVData(
        csvData[0],
        csvData[1],
        csvData[2],
        csvData[3],
        csvData[4],
        csvData[5],
        csvData[6],
        csvData[7],
        csvData[8]
      );
    });
  };

  const handleTableLoad = (data) => {
    action(mapToTable(data));
  };

  const handleOnFileLoad = (data, file) => {
    try {
      handleTableLoad(data.data);
      uploadFile(file, {
        onSuccess: (res) => {
          if (res.url) {
            uploadMasterCatalog(
              {
                supplier_id: record.id,
                file_url: res.url,
              },
              {
                onSuccess: (res) => {
                  if (res.includesConflicts) {
                    return;
                  }
                  if (res.success) {
                    openSnackbar();
                    if (isMasterCatalog) {
                      updateHasMasterCatalog(
                        {
                          has_official_catalog: isMasterCatalog,
                          id: record.id,
                        },
                        {
                          onSuccess: (res) => {
                            setTimeout(() => window.location.reload(), 600);
                          },
                        }
                      );
                    } else {
                      setTimeout(() => window.location.reload(), 600);
                    }
                  } else {
                    actionHandleError(res.errors);
                  }
                },
              }
            );
          }
        },
      });
    } catch (e) {}
  };

  const handleRemove = () => {
    action([]);
    actionHandleError([]);
  };

  return (
    <>
      <CSVReader
        onUploadAccepted={handleOnFileLoad}
        accept={[".csv", ".xlsx", ".xls"]}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            <div>
              <button type="button" {...getRootProps()}>
                Seleccionar archivo
              </button>
              <div>{acceptedFile && acceptedFile.name}</div>
              {acceptedFile && (
                <div>
                  <button
                    {...getRemoveFileProps()}
                    onClick={(evt) => {
                      getRemoveFileProps().onClick(evt);
                      handleRemove();
                    }}
                  >
                    Remover
                  </button>
                </div>
              )}
            </div>
            <ProgressBar />
          </>
        )}
      </CSVReader>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Catálogo subido o modificado exitosamente!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CatalogCSV;
