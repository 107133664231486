import React, { useState } from "react";
import {
    BooleanField,
    Datagrid,
    Edit,
    EditButton,
    List,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";

const defaultQuery = "listOrders";

export const ProductCategoriesList = (props) => {
    const [query, setQuery] = useState(defaultQuery);

    return (
        <List {...props}>
            <Datagrid>
                <TextField source="id" label="id" sortable={false}/>
                <TextField source="name" label="Nombre" sortable={false} />
                {/*<TextField source="price" label="Precio" sortable={false} />*/}
                {/*<TextField source="status" label="Estado" sortable={false} />*/}
                {/*<TextField source="stock" label="Inventario" sortable={false} />*/}
                {/*<ShowButton label="Ver" />*/}
                {/*<EditButton label="Editar" />*/}
            </Datagrid>
        </List>
    );
};

// export const OrdersEdit = (props) => (
//     <Edit {...props}>
//         <SimpleForm>
//             <TextInput source="id" disabled />
//             <TextInput
//                 source="name"
//                 label="Nombre"
//             />
//             <TextInput
//                 source="code"
//                 label="Código"
//             />
//             <TextInput
//                 source="unit"
//                 label="Unidad"
//             />
//             <TextInput
//                 source="price"
//                 label="Precio"
//             />
//             <TextInput
//                 source="currency_code"
//                 label="Código de moneda"
//             />
//             <TextInput
//                 source="sku"
//                 label="SKU"
//             />
//             <TextInput
//                 source="stock"
//                 label="Cantidades disponibles"
//             />
//             <TextInput
//                 source="brand"
//                 label="Marca"
//             />
//             {/*<NumberInput source="amount" validate={validateAmount} />*/}
//             {/*<DateInput source="date" validate={validateDate} />*/}
//         </SimpleForm>
//     </Edit>
// );
