import React from 'react';
import {Datagrid, DateField, List, TextField} from "react-admin";

export const RestaurantInvitations = ({ ...props }) => {
  return (
      <List {...props}>
          <Datagrid>
              <TextField source="restaurant_name" label="Nombre del restaurante" sortable={false} />
              <TextField source="__supplier__.name" label="Invitado por proveedor" sortable={false} />
              <TextField source="contact_name" label="Nombre de contacto" sortable={false} />
              <TextField source="email" label="Correo de contacto" sortable={false} defaultValue="-" />
              <TextField source="phone_number" label="Teléfono de contacto" sortable={false} defaultValue="-" />
              <DateField source="created_at" label="Creado el" sortable={false} defaultValue="-" />
          </Datagrid>
      </List>
  );
};
