import React, { useEffect } from 'react';

import { createStyles } from '@material-ui/core/styles';
import { useAuth0 } from "@auth0/auth0-react";

const styles = ({ spacing }) =>
    createStyles({
        button: {
            width: '100%',
        },
        icon: {
            marginRight: spacing.unit,
        },
    });

const LoginForm = () => {
    const { loginWithRedirect } = useAuth0();
    useEffect(() => {
        const handleLogin = () => {
            // userLogin(); // Do not provide code, just trigger the redirection
            loginWithRedirect();
        };
        handleLogin()
    }, [loginWithRedirect]);

    return (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
            Redirigiendo...
        </div>
    );
}


export default LoginForm;
