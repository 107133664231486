/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      restaurantID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      restaurantID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      restaurantID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      members {
        nextToken
        startedAt
      }
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      cognitoID
      picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teams {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      cognitoID
      picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teams {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      cognitoID
      picture
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teams {
        nextToken
        startedAt
      }
    }
  }
`;
export const createOrders = /* GraphQL */ `
  mutation CreateOrders(
    $input: CreateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    createOrders(input: $input, condition: $condition) {
      id
      listOfProducts
      notes
      status
      countProducts
      totalPrice
      createdBy
      deliveryDate
      restaurantID
      supplierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrders = /* GraphQL */ `
  mutation UpdateOrders(
    $input: UpdateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    updateOrders(input: $input, condition: $condition) {
      id
      listOfProducts
      notes
      status
      countProducts
      totalPrice
      createdBy
      deliveryDate
      restaurantID
      supplierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrders = /* GraphQL */ `
  mutation DeleteOrders(
    $input: DeleteOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    deleteOrders(input: $input, condition: $condition) {
      id
      listOfProducts
      notes
      status
      countProducts
      totalPrice
      createdBy
      deliveryDate
      restaurantID
      supplierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      name
      phone
      mobile
      address
      photoLogo
      email
      statusCatalogu
      managerID
      locations
      verified
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      catalogue {
        id
        title
        notes
        updatedBy
        listOfProducts
        valid
        createdBy
        supplierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      name
      phone
      mobile
      address
      photoLogo
      email
      statusCatalogu
      managerID
      locations
      verified
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      catalogue {
        id
        title
        notes
        updatedBy
        listOfProducts
        valid
        createdBy
        supplierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      name
      phone
      mobile
      address
      photoLogo
      email
      statusCatalogu
      managerID
      locations
      verified
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      catalogue {
        id
        title
        notes
        updatedBy
        listOfProducts
        valid
        createdBy
        supplierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRestaurant = /* GraphQL */ `
  mutation CreateRestaurant(
    $input: CreateRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    createRestaurant(input: $input, condition: $condition) {
      id
      name
      mobile
      address
      photoLogo
      managerID
      superAdmin
      locations
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teams {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateRestaurant = /* GraphQL */ `
  mutation UpdateRestaurant(
    $input: UpdateRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    updateRestaurant(input: $input, condition: $condition) {
      id
      name
      mobile
      address
      photoLogo
      managerID
      superAdmin
      locations
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teams {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteRestaurant = /* GraphQL */ `
  mutation DeleteRestaurant(
    $input: DeleteRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    deleteRestaurant(input: $input, condition: $condition) {
      id
      name
      mobile
      address
      photoLogo
      managerID
      superAdmin
      locations
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teams {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      unit
      price
      code
      stockStatus
      brand
      description
      allergies
      currency
      amount
      catalogueID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      unit
      price
      code
      stockStatus
      brand
      description
      allergies
      currency
      amount
      catalogueID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      unit
      price
      code
      stockStatus
      brand
      description
      allergies
      currency
      amount
      catalogueID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCatalogue = /* GraphQL */ `
  mutation CreateCatalogue(
    $input: CreateCatalogueInput!
    $condition: ModelCatalogueConditionInput
  ) {
    createCatalogue(input: $input, condition: $condition) {
      id
      title
      notes
      updatedBy
      listOfProducts
      valid
      createdBy
      supplierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      products {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCatalogue = /* GraphQL */ `
  mutation UpdateCatalogue(
    $input: UpdateCatalogueInput!
    $condition: ModelCatalogueConditionInput
  ) {
    updateCatalogue(input: $input, condition: $condition) {
      id
      title
      notes
      updatedBy
      listOfProducts
      valid
      createdBy
      supplierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      products {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCatalogue = /* GraphQL */ `
  mutation DeleteCatalogue(
    $input: DeleteCatalogueInput!
    $condition: ModelCatalogueConditionInput
  ) {
    deleteCatalogue(input: $input, condition: $condition) {
      id
      title
      notes
      updatedBy
      listOfProducts
      valid
      createdBy
      supplierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      products {
        nextToken
        startedAt
      }
    }
  }
`;
export const createTeamUserProfile = /* GraphQL */ `
  mutation CreateTeamUserProfile(
    $input: CreateTeamUserProfileInput!
    $condition: ModelTeamUserProfileConditionInput
  ) {
    createTeamUserProfile(input: $input, condition: $condition) {
      id
      teamID
      userprofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        restaurantID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      userprofile {
        id
        cognitoID
        picture
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeamUserProfile = /* GraphQL */ `
  mutation UpdateTeamUserProfile(
    $input: UpdateTeamUserProfileInput!
    $condition: ModelTeamUserProfileConditionInput
  ) {
    updateTeamUserProfile(input: $input, condition: $condition) {
      id
      teamID
      userprofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        restaurantID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      userprofile {
        id
        cognitoID
        picture
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTeamUserProfile = /* GraphQL */ `
  mutation DeleteTeamUserProfile(
    $input: DeleteTeamUserProfileInput!
    $condition: ModelTeamUserProfileConditionInput
  ) {
    deleteTeamUserProfile(input: $input, condition: $condition) {
      id
      teamID
      userprofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        restaurantID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      userprofile {
        id
        cognitoID
        picture
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
