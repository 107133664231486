// in src/MyAppBar.js
import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    // flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Futura",
    fontWeight: "bold",
  },
  subtitle: {
    // flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Futura",
  },
  spacer: {
    flex: 1,
  },
});

const ListoAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props}>
      <Typography
        variant="h5"
        color="inherit"
        className={classes.title}
        children={"Alisto"}
      />
      <Typography
        variant="h6"
        color="inherit"
        children={<span>&nbsp;>&nbsp;</span>}
      />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.subtitle}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default ListoAppBar;
