import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  FunctionField,
} from "react-admin";

export const RestaurantUserInvitations = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField
          source="restaurant.name"
          label="Nombre del restaurante"
          sortable={false}
        />
        <FunctionField
          label="Invitado por"
          source="restaurant_user"
          render={(record) => `${record.first_name} ${record.last_name}`}
        />
        <TextField source="first_name" label="Nombre" sortable={false} />
        <TextField
          source="last_name"
          label="Apellido"
          sortable={false}
          defaultValue="-"
        />
        <TextField
          source="email"
          label="Correo de contacto"
          sortable={false}
          defaultValue="-"
        />
        <TextField
          source="phone"
          label="Teléfono de contacto"
          sortable={false}
          defaultValue="-"
        />
        <TextField source="role" label="Rol" sortable={false} />
        <TextField source="status" label="Estatus" sortable={false} />
        <DateField
          source="created_at"
          label="Creado el"
          sortable={false}
          defaultValue="-"
        />
      </Datagrid>
    </List>
  );
};
