import { UseQueryOptions } from 'react-query';

export const QueryTypes = {
    CATALOG: 'CATALOG',
    APP_VERSION: 'APP_VERSION'
};

export interface UseQueryOpts<T, R> extends UseQueryOptions<T, R> {
    refetchOnFocus?: boolean;
}

export interface BaseApiRequest<T> {
    data: T[],
    links: {
        current: string;
    },
    meta: {
        currentPage: number,
        itemsPerPage: number,
        sortBy: string[][],
        totalItems: number,
        totalPages: number,
    }
}
