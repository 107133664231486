import React, { useEffect } from "react";
import { Admin, defaultTheme, Resource } from "react-admin";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import { merge } from "lodash";
import ListoLayout from "./components/general/ListoLayout";
import {
  SupplierCreate,
  SupplierEdit,
  SupplierList,
  SupplierShow,
} from "./components/Supplier";
import ListoLogin from "./components/general/ListoLogin";
import {
  ProductsCreate,
  ProductsEdit,
  ProductsList,
  ProductsShow,
} from "./components/Products";
import authProvider from "./utils/authProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { OrdersEdit, OrdersList, OrdersShow } from "./components/Orders";
import { AllergensList } from "./components/Allergens";
import { ProductCategoriesList } from "./components/Products-Categories";
import {
  RestaurantEdit,
  RestaurantList,
  RestaurantShow,
} from "./components/Restaurant";
import { dataProviderListo } from "./services/dataProvider";
import { listoCustomTheme } from "./utils/listoTheme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ProductUnitsList } from "./components/Products-Units";
import { addAccessTokenInterceptor } from "./services/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SupplierUserInvitations } from "./components/Supplier-User-Invitations";
import { RestaurantUserInvitations } from "./components/Restaurant-User-Invitations";
import { RestaurantInvitations } from "./components/Restaurant-Invitations";
import { SupplierInvitations } from "./components/Supplier-Invitations";
import { AppSettingsList } from "./components/App-Settings";

export const listoTheme = merge({}, defaultTheme, listoCustomTheme);

Sentry.init({
  dsn: "https://00ac398743e14dc3ade9398bbdc358a9@o4504493557153793.ingest.sentry.io/4504571335999488",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

const App = () => {
  const queryClient = new QueryClient();
  const {
    isAuthenticated,
    logout,
    loginWithRedirect,
    isLoading,
    user,
    handleRedirectCallback,
    getAccessTokenSilently,
  } = useAuth0();
  const customAuthProvider = authProvider({
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    isLoading,
    handleRedirectCallback,
  });

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Admin
        authProvider={customAuthProvider}
        dataProvider={dataProviderListo} // Replace the Admin component of react-admin
        operations={{ queries, mutations }} // Pass the queries and mutations
        theme={listoTheme}
        layout={ListoLayout}
        loginPage={ListoLogin}
      >
        <Resource
          name="suppliers"
          list={SupplierList}
          show={SupplierShow}
          create={SupplierCreate}
          edit={SupplierEdit}
          options={{ label: "Proveedores" }}
        />
        <Resource
          name="restaurants"
          list={RestaurantList}
          show={RestaurantShow}
          edit={RestaurantEdit}
          options={{ label: "Restaurantes" }}
        />
        <Resource
          name="restaurants-invitations"
          list={RestaurantInvitations}
          options={{ label: "Invitaciones a Restaurantes" }}
        />
        <Resource
          name="suppliers-invitations"
          list={SupplierInvitations}
          options={{ label: "Invitaciones a Proveedores" }}
        />
        <Resource
          name="suppliers-user-invitations"
          list={SupplierUserInvitations}
          options={{ label: "Invitaciones a usuarios de Proveedores" }}
        />
        <Resource
          name="restaurants-user-invitations"
          list={RestaurantUserInvitations}
          options={{ label: "Invitaciones a usuarios de Restaurantes" }}
        />
        <Resource
          name="products"
          list={ProductsList}
          edit={ProductsEdit}
          create={ProductsCreate}
          show={ProductsShow}
          options={{ label: "Productos" }}
        />
        <Resource
          name="orders"
          list={OrdersList}
          edit={OrdersEdit}
          show={OrdersShow}
          options={{ label: "Pedidos" }}
        />
        <Resource
          name="allergens"
          list={AllergensList}
          options={{ label: "Alergénicos" }}
        />
        <Resource
          name="product-categories"
          list={ProductCategoriesList}
          options={{ label: "Categorías de productos" }}
        />
        <Resource
          name="product-units"
          list={ProductUnitsList}
          options={{ label: "Unidades de productos" }}
        />
        <Resource
          name="app-settings"
          list={AppSettingsList}
          options={{ label: "Versiones de la aplicación" }}
        />
      </Admin>
    </QueryClientProvider>
  );
};

export default App;
